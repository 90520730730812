import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: Ubuntu, sans-serif;
    font-size: 19px;
    line-height: 1.16;
    color: #4E4E4E;
    background-color: #fafafa;
  }

  h2 {
    font-size: 25px;
    line-height: 29px;
  }

  p {
    font-size: 25px;
    line-height: 33px;
  }
`

export default GlobalStyles
