import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import Container from '../common/Container/Container'
import Navigation from '../Navigation/Navigation'
import logo from '../../../static/icons/logo.svg'
import {
  LogoLink,
  Logo,
  StyledHeader,
  HeaderContent,
  HeaderMenu,
  HeaderBurger,
  HeaderInner,
} from './styles'

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const toggleMenu = () => setOpen(!isOpen)
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  return (
    <StyledHeader>
      <HeaderInner>
        <Container>
          <HeaderContent>
            <LogoLink to='/'>
              <Logo src={logo} alt='Kai camera' />
            </LogoLink>
            <HeaderBurger isOpen={isOpen} onClick={toggleMenu} />
            {!isMobile && <Navigation />}
          </HeaderContent>
        </Container>
      </HeaderInner>
      {isMobile && (
        <HeaderMenu isOpen={isOpen}>
          <Navigation />
        </HeaderMenu>
      )}
    </StyledHeader>
  )
}

export default Header
