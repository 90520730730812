import styled from 'styled-components'
import { Link } from 'gatsby'
import Burger from '../Burger/Burger'

export const LogoLink = styled(Link)`
  z-index: 10;
  width: 110px;
  height: 50px;
`
export const Logo = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

export const StyledHeader = styled.header`
  box-sizing: border-box;
  width: 100%;
`

export const HeaderInner = styled.div`
  position: relative;
  background-color: #ffffff;
  border-bottom: 1px solid #efefef;
  z-index: 10;
`

export const HeaderContent = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 79px;
  width: 100%;
`

export const HeaderBurger = styled(Burger)`
  @media (min-width: 768px) {
    display: none;
  }
`

export const HeaderMenu = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  transform: translateY(calc(-100%));
  transform: ${({ isOpen }) => isOpen && 'translateY(0)'};
  background-color: #ffffff;
  transition: all 0.5s ease;
`
