import React from 'react'
import { StyledBurger } from './styles'

const Burger = ({ isOpen, onClick, ...rest }) => (
  <StyledBurger onClick={onClick} isOpen={isOpen} {...rest}>
    <span />
    <span />
    <span />
    <span />
  </StyledBurger>
)

export default Burger
