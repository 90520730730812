import styled from 'styled-components'

export const StyledFooter = styled.footer`
  width: 100%;
  background-color: #f9c806;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 27px;
  padding-bottom: 31px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const FooterText = styled.span`
  color: #ffffff;
`

export const FooterLink = styled(FooterText)`
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const FooterNavigation = styled.nav`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`
