import styled, { css } from 'styled-components'

export const StyledBurger = styled.div`
  z-index: 100;
  width: 30px;
  height: 22px;
  display: flex;
  transform: rotate(0);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #f9c806;
    opacity: 1;
    left: 0;
    transform: rotate(0);
    transition: all 0.5s ease-in-out;
  }
  span:nth-child(1) {
    top: 0;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }

  ${p => p.isOpen
    && css`
      span:nth-child(1),
      span:nth-child(4) {
        top: 10px;
        width: 0;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
        background: #f9c806;
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
        background: #f9c806;
      }
    `}
`
