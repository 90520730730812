import styled from 'styled-components'

export const Main = styled.main`
  min-height: calc(100vh - 80px - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 110px;
    background-size: 110px 84px;
    background-image: url('/icons/cameranew.png'), url('/icons/cameranew.png'),
      url('/icons/cameranew2.png'), url('/icons/cameranew2.png');
    background-position: 0 0, 55px 20px, 0 40px, 55px 60px;
    background-repeat: repeat-x;
    opacity: 0.4;
  }
`
