import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 995px;
  margin: 0 auto;
  padding: 0 30px;

  ${props => props.small
    && css`
      max-width: 655px;
    `}
`
