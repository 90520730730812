import React from 'react'
import Container from '../common/Container/Container'
import Link from '../common/Link/Link'
import {
  FooterText,
  StyledFooter,
  FooterContent,
  FooterNavigation,
  FooterLink,
} from './styles'

const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterContent>
        <FooterText>&copy; kai.camera, 2019</FooterText>
        <FooterNavigation>
          <FooterLink as={Link} to='/privacy-policy'>
            Privacy&nbsp;Policy
          </FooterLink>
          <FooterLink as={Link} to='/terms-and-conditions'>
            Terms&nbsp;&&nbsp;Conditions
          </FooterLink>
        </FooterNavigation>
      </FooterContent>
    </Container>
  </StyledFooter>
)

export default Footer
