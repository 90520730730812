import React from 'react'
import { NavList, NavLink } from './styles'
import menuLinks from '../../../content/main-menu.json'

const Navigation = () => (
  <nav>
    <NavList>
      {menuLinks.map(({ path, name }) => (
        <li key={path}>
          <NavLink to={path}>{name}</NavLink>
        </li>
      ))}
    </NavList>
  </nav>
)

export default Navigation
