import React from 'react'
import { StyledContainer } from './styles'

const Container = ({ children, small }) => (
  <>
    <StyledContainer small={small}>{children}</StyledContainer>
  </>
)

export default Container
