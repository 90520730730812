import styled from 'styled-components'
import { Link } from 'gatsby'

export const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    li {
      width: 100%;
      margin: 0;
    }
  }
`

export const NavLink = styled(Link)`
  color: #4e4e4e;
  opacity: 0.6;
  text-decoration: none;

  &:hover {
    color: #f9c806;
  }

  @media (max-width: 767px) {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: 15px;
    text-align: center;
  }
`
